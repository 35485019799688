import React, { Component } from "react"
import Layout from "../layouts/index"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "./css/index.css"
import "./css/team.css"
import Popup from 'reactjs-popup';
import ExternalButton from "../components/master/buttons/externalButton"
import Img from "gatsby-image"
import BruinLogo from "../images/media/bruin-letter.png"

class MediaPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageIsLoaded: false,
      open : false,
    }
    this.showContent = this.showContent.bind(this)
  }

  focusMain() {
    setTimeout(function() {
      // Focus the element on the burguer menu
      document.getElementById("close__menu").focus()

      document.querySelectorAll("html")[0].classList.add("html__custom")
      // Trap the focus loop inside the menu
      var theElement = document.querySelectorAll(".popup__inner")[0].id

      var element = document.getElementById(theElement)

      var focusableEls = false

      if (theElement === "popup__main") {
        focusableEls = document.querySelectorAll(
          "#popup__main .close, #popup__main a, #popup__main iframe "
        )
        document
          .querySelectorAll(".team__page .main__section")[0]
          .classList.add("no__clipPath")
      }

      if (theElement === "popup__team") {
        if(document.getElementById("d-00001-member")){
          if(window.screen.width < 1100){
            document
            .querySelectorAll(".team__page .main__section")[0]
            .classList.add("no__clipPath")
          }
          document.body.scrollTop = 100; // For Safari
          document.documentElement.scrollTop = 100; // For Chrome, Firefox, IE and Opera
        }
        focusableEls = document.querySelectorAll(
          "#popup__team .close, #popup__team .btn-main a "
        )
      }

      var firstFocusableEl = focusableEls[0],
        lastFocusableEl = focusableEls[focusableEls.length - 1],
        KEYCODE_TAB = 9

      element.addEventListener("keydown", function(e) {
        var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB
        if (!isTabPressed) {
          return
        }

        if (e.shiftKey) {
          /* shift + tab */ if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus()
            e.preventDefault()
          }
        } /* tab */ else {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus()
            e.preventDefault()
          }
        }
      })
    }, 1)
  }
  endModal(e) {
    document.querySelectorAll("html")[0].classList.remove("html__custom")
    document
      .querySelectorAll(".team__page .main__section")[0]
      .classList.remove("no__clipPath")

    e.preventDefault()
    if (e.type === "keypress") {
      if (e.which === 32 || e.which === 13) {
        ;[...document.querySelectorAll(".popup-overlay")][0].remove()
      }
    } else {
      ;[...document.querySelectorAll(".popup-overlay")][0].remove()
    }
  }
  changeBodyScroll() {
    // When the modal is hidden...
    document.querySelectorAll("html")[0].classList.remove("html__custom")
    document
      .querySelectorAll(".team__page .main__section")[0]
      .classList.remove("no__clipPath")
  }
  showContent() {
    document.querySelectorAll(".team__extra__copy")[0].classList.add("--show")
    document
      .querySelectorAll(".team__extra__second__copy")[0]
      .classList.add("--show")
    document
      .querySelectorAll(".team__extra__content")[0]
      .classList.add("--show")
    document.querySelectorAll(".team__page__first")[0].classList.add("--open")
    document.querySelectorAll(".team__section")[0].classList.add("--open")

    document
      .querySelectorAll(".team__page__first button")[0]
      .classList.add("remove__content")

    this.setState({
      pageIsLoaded: true,
    })
  }
  hideContent() {
    document
      .querySelectorAll(".team__extra__copy")[0]
      .classList.remove("--show")
    document
      .querySelectorAll(".team__extra__second__copy")[0]
      .classList.remove("--show")
    document
      .querySelectorAll(".team__extra__content")[0]
      .classList.remove("--show")
    document
      .querySelectorAll(".team__page__first")[0]
      .classList.remove("--open")
    document.querySelectorAll(".team__section")[0].classList.remove("--open")

    document
      .querySelectorAll(".team__page__first button")[0]
      .classList.remove("remove__content")
  }
  triggerClientPopUp(event) {

    event.preventDefault()
    if (document.getElementsByClassName("popup-overlay ").length > 0) {
      let idPopup = window.localStorage.getItem("last-team-member")
      document.getElementById(idPopup).focus()
    } else {
      if (event.type === "keypress") {
        if (event.which === 32 || event.which === 13) {
          let idPopup = event.target.getAttribute("datatracknumber")
          document
            .getElementsByClassName(idPopup)[0]
            .childNodes[1].childNodes[2].click()

          window.localStorage.setItem("last-team-member", idPopup)
        }
      }
    }
  }

  render() {
    const pageData = this.props.data.allWordpressPage.edges[0].node
    var pageAcf = this.props.data.allWordpressPage.edges[0].node.acf

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={pageData.yoast_meta.yoast_wpseo_metadesc}
          />
          <title>Our Team | Bruin Capital</title>
          <link
            rel="canonical"
            href={pageData.yoast_meta.yoast_wpseo_canonical}
          />
        </Helmet>
        <div className={"team__page"}>
          <section
            className={"main__section container-fluid  team__page__first"}
          >
            <div className="page__background">
              <div className={"fade__top"}></div>
              {pageData.featured_media ? (
                <img
                  src={
                    pageData.featured_media.source_url
                  }
                  alt={""}
                  tabIndex={-1}
                />
              ) : (
                ""
              )}
            </div>
            <div className="media__list__background">
              <img src={BruinLogo} alt={""} />
            </div>
            <div className={"container"}>
              <div className={"row main__row"}>
                <div className={"col-md-12 col-xl-6"}>
                  {pageAcf.main_copy.length > 0 ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: pageAcf.main_copy }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                
                <div className="col-xl-12 ">
                  <Popup
                      modal
                      closeOnEscape
                      closeOnDocumentClick
                      onOpen={this.focusMain}
                      onClose={this.changeBodyScroll}
                      key={`d-00001`}
                      trigger={
                        <div>
                          <button
                            tabIndex={0}
                            alt={`George Pyne, member Bruin Sport Capital Team`}
                            className={`team-00001`}
                            loading="lazy"
                          >READ MORE</button>
                        </div>
                          
                      }
                    >
                      {close => (
                        <div className="team__top__popup" id="d-00001-member">
                          <div
                            className="popup__inner featured__wrapper"
                            id={"popup__team"}
                          >
                            <div className="triangle__big"></div>
                            <div className="triangle__small"></div>

                            <div
                              className="featured__article row"
                              key={`team-00001`}
                            >
                              <div className={"col-md-12 col-lg-4 test"}>
                                {pageAcf.team_members[0].photo ? (

                                  <img
                                    tabIndex={0}
                                    src={
                                      pageAcf.team_members[0].photo.source_url
                                    }
                                    alt={`${pageAcf.team_members[0].name}, member Bruin Sport Capital Team`}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={"col-md-12 col-lg-8"}>
                                <div className="featured__artitle__inner">
                                  <div className="featured__article__top">
                                    <div className="featured__article__title">
                                      {pageAcf.team_members[0].name.length > 0 ? (
                                        <div>
                                          <h4 dangerouslySetInnerHTML={{ __html: pageAcf.team_members[0].name }} />
                                          
                                          {pageAcf.team_members[0].position.length > 0 ? (
                                            <p
                                              className="featured__article__position"
                                              dangerouslySetInnerHTML={{
                                                __html: pageAcf.team_members[0].position,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          {pageAcf.team_members[0].twitter_username.length > 0 ? (
                                            <h5><strong>FOLLOW <span dangerouslySetInnerHTML={{ __html: pageAcf.team_members[0].name.split(" ")[0] }} />: <img className="alignnone size-full wp-image-1698" src="https://cms.bruincptl.com/wp-content/uploads/2022/04/01_BRUIN_v3.png" alt="" width="23" height="23" /> / <a href={`https://twitter.com/${pageAcf.team_members[0].twitter_username}`} target="_blank">@{pageAcf.team_members[0].twitter_username}</a></strong></h5>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="featured__position__meta">

                                        <div className="featured__article__meta__links" data-email={pageAcf.team_members[0].email}>
                                          
                                          {pageAcf.team_members[0].email.length > 0 ? (
                                            <a
                                              href={`mailto:${pageAcf.team_members[0].email}`} className="featured__article__meta__links__email"
                                            ><img src="https://cms.bruincptl.com/wp-content/uploads/2022/04/email.png" /></a>
                                          ) : (
                                            ""
                                          )}
                                          {pageAcf.team_members[0].member_link.length > 0 ? (
                                            <a
                                              href={pageAcf.team_members[0].member_link}
                                              target="_blank"
                                              rel="noopener"
                                            ><img src="https://cms.bruincptl.com/wp-content/uploads/2022/04/linkedin.png" /></a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {pageAcf.team_members[0].bio.length > 0 ? (
                                    <div className="featured__article__content">
                                      <div className={"line"}></div>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: pageAcf.team_members[0].bio,
                                        }}
                                      />
                                      
                                      <div className="col-xl-12  team__extra__content">
                                        <>
                                          {pageAcf.video_iframe ? (
                                            <div
                                              className={
                                                "iframe__team d-flex justify-content-center align-items-center"
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html: pageAcf.video_iframe,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>

                                        {pageAcf.popup_logos ? (
                                          <div className="popup__logos">
                                            {pageAcf.popup_logos.map((logo, index) => (
                                              <div className="logo__wrapper" key={index}>
                                                {logo.logo ? (
                                                  <div className="team__logo__image">
                                                    <a
                                                      href={logo.url}
                                                      target={"_BLANK"}
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        src={
                                                          logo.logo.source_url
                                                        }
                                                        alt={logo.title}
                                                        loading="lazy"
                                                      />
                                                    </a>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {logo.title.length > 0 ? (
                                                  <p
                                                    className={"text-white"}
                                                    dangerouslySetInnerHTML={{ __html: logo.title }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                    </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                              className="close"
                              tabIndex="0"
                              onClick={close}
                              onKeyPress={close}
                              id="close__menu"
                          ></button>
                        </div>
                      )}
                  </Popup>
                </div>
                
              </div>
            </div>
          </section>
          {pageAcf.team_members ? (
            <section className={"team__section container-fluid"}>
              <div className={"container"}>
                <div className={"row"}>
                  <div className={"col-md-12 col-xl-6"}>
                    {pageAcf.team_copy.length > 0 ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: pageAcf.team_copy }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={"col-md-12 col-xl-6"}></div>
                </div>
                <div className={"row team__members"}>
                  {pageAcf.extra.map((member, index) => (
                    <div
                      className={
                        `col-xs-6 col-sm-6 col-md-6 col-xl-4 team-wrapper team-wrapper-${index}`
                      }
                      key={`${member.name}-${index}`}
                      tabIndex={0}
                      onClick={this.triggerClientPopUp}
                      onKeyPress={this.triggerClientPopUp}
                      datatracknumber={`team-${index}`}
                      id={`team-${index}`}
                    >
                      <Popup
                        modal
                        closeOnEscape
                        closeOnDocumentClick
                        onOpen={this.focusMain}
                        onClose={this.changeBodyScroll}
                        key={`d-${index}`}
                        trigger={
                          <div>
                            <img
                              tabIndex={0}
                              src={member.photo.source_url}
                              alt={`${member.name}, member Bruin Sport Capital Team`}
                              className={`team-${index}`}
                              loading="lazy"
                            />
                          </div>
                            
                        }
                      >
                        {close => (
                          <div>
                            <div
                              className="popup__inner featured__wrapper"
                              id={"popup__team"}
                            >
                              <div className="triangle__big"></div>
                              <div className="triangle__small"></div>

                              <div
                                className="featured__article row"
                                key={index}
                              >
                                <div className={"col-md-12 col-lg-4"}>
                                  {member.photo ? (
                                    <img
                                      tabIndex={0}
                                      src={
                                        pageAcf.team_members[index].photo.source_url
                                      }
                                      alt={`${member.name}, member Bruin Sport Capital Team`}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className={"col-md-12 col-lg-8"}>
                                  <div className="featured__artitle__inner">
                                    <div className="featured__article__top">
                                      <div className="featured__article__title">
                                        {member.name.length > 0 ? (
                                          <h4
                                            className="featured__article__name"
                                            dangerouslySetInnerHTML={{
                                              __html: member.name,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <div className="featured__position__meta">
                                          {member.position.length > 0 ? (
                                            <p
                                              className="featured__article__position"
                                              dangerouslySetInnerHTML={{
                                                __html: member.position,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          <div className="featured__article__meta__links" data-email={member.email}>
                                            
                                            {member.email.length > 0 ? (
                                              <a
                                                href={`mailto:${member.email}`} className="featured__article__meta__links__email"
                                              ><img src="https://cms.bruincptl.com/wp-content/uploads/2022/04/email.png" /></a>
                                            ) : (
                                              ""
                                            )}
                                            {member.member_link.length > 0 ? (
                                              <a
                                                href={member.member_link}
                                                target="_blank"
                                                rel="noopener"
                                              ><img src="https://cms.bruincptl.com/wp-content/uploads/2022/04/linkedin.png" /></a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {member.bio.length > 0 ? (
                                        <div className="featured__article__content">
                                          <div className={"line"}></div>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: member.bio,
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                              className="close"
                              tabIndex="0"
                              onClick={close}
                              onKeyPress={close}
                              id="close__menu"
                            ></button>
                          </div>
                        )}
                      </Popup>
                      <div className={"team__inside"}>
                        {member.name.length > 0 ? (
                          <h4
                            dangerouslySetInnerHTML={{ __html: member.name }}
                          />
                        ) : (
                          ""
                        )}
                        {member.position.length > 0 ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: member.position,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      </Layout>
    )
  }
}
export default MediaPage

export const pageQuery = graphql`
  query teamPageQuery {
    allWordpressPage(filter: { path: { eq: "/team/" } }) {
      edges {
        node {
          id
          title
          content
          yoast_meta {
            yoast_wpseo_metadesc
            yoast_wpseo_title
            yoast_wpseo_canonical
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            id
            source_url
          }
          acf {
            main_copy
            first_column_copy
            second_column_copy
            video_iframe
            popup_logos {
              logo {
                source_url
              }
              title
              url
            }
            team_copy
            team_members {
              photo {
                source_url
              }
              name
              position
              bio
              member_link
              email
              twitter_username
            }
            extra: team_members {
              photo {
                source_url
              }
              name
              position
              bio
              member_link
              email
              twitter_username
            }
          }
        }
      }
    }
  }
`